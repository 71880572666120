.os-tabs-container {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.os-tabs-inner {
  flex: 1 1;
  display: flex;
  padding: 0px 20px;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 0 -20px;
  box-sizing: border-box;
}
.os-tabs-inner-small {
  box-sizing: border-box;
  margin: 0;
}
.os-tabs-icon {
  position: absolute;
  height: 45px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #949494;
  transition: all 0.2s ease;
}
.os-tabs-shadow {
  float: left;
  height: 45px;
  width: 30px;
  position: absolute;
}
.os-tabs-item {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
}
.os-tabs-item a {
  display: flex;
  align-items: center;
  height: 100%;
  color: #949494;
  font-size: 18px;
  border-bottom: transparent 1px solid;
  box-sizing: border-box;
}
.os-tabs-item:last-child::after {
  width: 20px;
  content: '';
}
.os-tabs-item:not(.os-tabs-itemActive) a:hover {
  border-bottom: 1px solid #e1e1e1;
}
.os-tabs-itemActive a {
  color: #0069ff;
  border-bottom: 1px solid #0069ff;
}
.os_navbar {
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print {
  .os_navbar {
    display: none;
  }
}
.os_navbar_margin {
  height: 80px;
}
@media print {
  .os_navbar_margin {
    display: none;
  }
}
.os_navbar-logo {
  height: 44px;
}
.os_bounce_loading {
  text-align: center;
}
.os_bounce_loading > div {
  border-radius: 100%;
  display: inline-block;
  margin: 0px 5px;
  -webkit-animation-name: bouncedelay;
          animation-name: bouncedelay;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.os_bounce_loading .os_bounce_bounce2 {
  -webkit-animation-delay: 0.16s;
          animation-delay: 0.16s;
}
.os_bounce_loading .os_bounce_bounce3 {
  -webkit-animation-delay: 0.32s;
          animation-delay: 0.32s;
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/* Copyright 2013-2015 etc Chris Tabor. See https://github.com/christabor/css-progress-wizard/blob/master/LICENSE for details. */
.os_wizard_container {
  position: relative;
  z-index: 10;
  margin-top: 20px;
}
.os_wizard_content {
  margin-top: 50px;
}
.progress-indicator {
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
}
.progress-indicator > li {
  flex: 1 1;
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #aaa;
  display: block;
}
.progress-indicator > li .bubble {
  z-index: 2;
  border-radius: 1000px;
  width: 15px;
  height: 15px;
  background-color: #aaa;
  display: block;
  margin: 0 auto 0.5em auto;
}
.progress-indicator > li .bubble:before,
.progress-indicator > li .bubble:after {
  display: block;
  position: absolute;
  z-index: -1;
  top: 4.5px;
  width: 100%;
  height: 5px;
  content: '';
  background-color: #eee;
}
.progress-indicator > li .bubble:before {
  left: 0;
}
.progress-indicator > li .bubble:after {
  right: 50%;
}
.progress-indicator > li:first-child .bubble:before,
.progress-indicator > li:first-child .bubble:after {
  width: 0%;
  margin-left: 50%;
}
.progress-indicator > li:last-child .bubble:before,
.progress-indicator > li:last-child .bubble:after {
  width: 50%;
  margin-right: 50%;
}
.progress-indicator > li.completed {
  color: #0069ff;
}
.progress-indicator > li.completed .bubble {
  background-color: #0069ff;
  color: #0069ff;
}
.progress-indicator > li.completed .bubble:before,
.progress-indicator > li.completed .bubble:after {
  background-color: #0069ff;
}
.progress-indicator > li.active {
  color: #0069ff;
}
.progress-indicator > li.active .bubble {
  background-color: #0069ff;
  color: #0069ff;
}
.progress-indicator > li.active .bubble:before,
.progress-indicator > li.active .bubble:after {
  background-color: #0069ff;
}
.progress-indicator > li a:hover .bubble {
  background-color: #5671d0;
  color: #5671d0;
}
.progress-indicator > li a:hover .bubble:before,
.progress-indicator > li a:hover .bubble:after {
  background-color: #5671d0;
}
.progress-indicator > li.danger .bubble {
  background-color: #d3140f;
  color: #d3140f;
}
.progress-indicator > li.danger .bubble:before,
.progress-indicator > li.danger .bubble:after {
  background-color: #d3140f;
}
.progress-indicator > li.warning .bubble {
  background-color: #edb10a;
  color: #edb10a;
}
.progress-indicator > li.warning .bubble:before,
.progress-indicator > li.warning .bubble:after {
  background-color: #edb10a;
}
.progress-indicator > li.info .bubble {
  background-color: #5b32d6;
  color: #5b32d6;
}
.progress-indicator > li.info .bubble:before,
.progress-indicator > li.info .bubble:after {
  background-color: #5b32d6;
}
.progress-indicator.stacked {
  display: block;
}
.progress-indicator.stacked > li {
  text-indent: -10px;
  text-align: center;
  display: block;
}
.progress-indicator.stacked > li .bubble:before,
.progress-indicator.stacked > li .bubble:after {
  left: 50%;
  margin-left: -2.5px;
  width: 5px;
  height: 100%;
}
.progress-indicator.stacked .stacked-text {
  position: relative;
  z-index: 10;
  top: 0;
  margin-left: 60% !important;
  width: 45% !important;
  display: inline-block;
  text-align: left;
  line-height: 1.2em;
}
.progress-indicator.stacked > li a {
  border: none;
}
.progress-indicator.stacked.nocenter > li .bubble {
  margin-left: 0;
  margin-right: 0;
}
.progress-indicator.stacked.nocenter > li .bubble:before,
.progress-indicator.stacked.nocenter > li .bubble:after {
  left: 7.5px;
}
.progress-indicator.stacked.nocenter .stacked-text {
  width: auto !important;
  display: block;
  margin-left: 30px !important;
}
@media handheld, screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 80%;
  }
}
.os_modal_content {
  outline: none;
  background-color: #fff;
  padding: 20px;
}
.os_modal_content .os_title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}
.os_modal_content .os_message {
  margin-bottom: 20px;
}
.os_modal_content .os_buttons {
  text-align: right;
}
.os_modal_content .os_cancelButton {
  margin-right: 10px;
}
@media only screen and (max-width: 480px) {
  .os_modal_content .os_title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }
  .os_modal_content .os_buttons {
    text-align: center;
  }
}
.os_container {
  margin: 0 auto;
  padding: 0 20px;
  padding: 0 calc(20px + env(safe-area-inset-left));
}
.os_container_big {
  max-width: 1200px;
}
.os_container_medium {
  max-width: 900px;
}
.os_container_small {
  max-width: 600px;
}
.os_buttonLink {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.os_buttonLink:hover {
  text-decoration: none;
}
.orion_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  min-width: 15px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
  text-decoration: none;
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.os_button_container {
  margin-right: 10px;
}
.os_button_container:last-child {
  margin-right: auto;
}
.orion_button * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.orion_fullWidth {
  display: block;
}
.orion_button:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.orion_button:active {
  background-color: #f6f9fc;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.orion_disabled {
  color: #9e9e9e;
  background: #e0e0e0;
  box-shadow: none;
}
.orion_disabled:hover {
  color: #9e9e9e;
  background: #e0e0e0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  cursor: default;
}
.orion_loading {
  color: #9e9e9e;
  background: #e0e0e0;
  box-shadow: none;
}
.orion_loading:hover {
  color: #9e9e9e;
  background: #e0e0e0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  cursor: default;
}
.orion_disabled:active {
  background: #e0e0e0;
}
.orion_primary {
  color: white;
  background: #2196f3;
}
.orion_primary:active {
  background-color: #1e88e5;
}
.orion_danger {
  color: white;
  background: #f44336;
}
.orion_danger:active {
  background-color: #e53935;
}
.orion_big {
  height: 45px;
  line-height: 45px;
  padding: 0 14px;
  font-size: 18px;
  letter-spacing: 0.04em;
}
.os_dropover {
  z-index: 1;
  position: absolute;
  display: flex;
  min-width: 220px;
  flex-direction: column;
  overflow: hidden;
  right: 0;
  top: 46px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}
.os_dropover-enter,
.os_dropover-leave.os_dropover-leave-active {
  opacity: 0;
  -webkit-transform: rotateX(-15deg);
          transform: rotateX(-15deg);
  -webkit-transform-origin: 50% -50px;
          transform-origin: 50% -50px;
}
.os_dropover-enter.os_dropover-enter-active,
.os_dropover-leave {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  transition: all 300ms ease;
}
.os-navigator-container {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.os-navigator-inner {
  flex: 1 1;
  display: flex;
  padding: 0px 20px;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 0 -20px;
  box-sizing: content-box;
}
.os-navigator-inner-small {
  box-sizing: border-box;
  margin: 0;
}
.os-navigator-icon {
  position: absolute;
  height: 60px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.2s ease;
}
.os-navigator-shadow {
  float: left;
  height: 60px;
  width: 30px;
  position: absolute;
}
.os-navigator-item {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
}
.os-navigator-item a {
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  font-family: 'Geomanist', 'Open Sans', sans-serif;
  font-size: 20px;
  border-bottom: transparent 2px solid;
  box-sizing: border-box;
}
.os-navigator-item:last-child::after {
  width: 20px;
  content: '';
}
.os-navigator-itemActive a {
  border-bottom: #fff 2px solid;
}
.os_autocomplete_item {
  padding: 8px 10px;
  font-size: 16px;
  color: #373737;
  cursor: pointer;
}
.os_autocomplete_item.highlighted {
  background-color: #f4f4f4;
}
.os-checkbox-container label {
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.os-checkbox-label {
  margin-left: 5px;
  -webkit-user-select: none;
          user-select: none;
}
.os_toggle_container {
  margin-top: 7px;
}
.os_toggle_label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -9px;
  margin-left: 10px;
  color: #464646;
  font-size: 18px;
  -webkit-user-select: none;
          user-select: none;
}
.react-ios-switch-Switch-handle {
  border-radius: 13px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 4px 11px 0px rgba(0, 0, 0, 0.08), -1px 3px 3px 0px rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  cursor: grab;
  height: 26px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 26px;
}
.react-ios-switch-Switch-handle:active {
  cursor: grabbing;
}
.react-ios-switch-Switch-input {
  display: none;
}
.react-ios-switch-Switch-offState {
  border-radius: 13px;
  height: 26px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 46px;
}
.react-ios-switch-Switch-switch {
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  width: 50px;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled {
  cursor: default;
  opacity: 0.5;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled .react-ios-switch-Switch-handle {
  cursor: default;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled .react-ios-switch-Switch-handle:active {
  cursor: default;
}
.os-s-array {
  background-color: #fff;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 10px;
  display: flex;
  border-left: 1px solid #eee;
  cursor: grab;
}
.os-s-array-grabbing {
  cursor: grabbing;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.os-array-item-content {
  flex: 1 1;
}
.os-array-item-remove {
  padding-top: 2px;
  padding-left: 10px;
}
.os-colorpicker-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}
.os-colorpicker-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.os-colorpicker-popover {
  position: absolute;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: 15px;
}
.os-colorpicker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.os-input-container {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
}
.os-input-text {
  flex: 1 1;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  transition: border 0.2s ease;
  box-shadow: 0 0 0 1px rgba(220, 220, 220, 0.2);
}
.os-input-text:focus {
  border: 1px solid #0069ff;
  box-shadow: 0 0 0 0.5px rgba(49, 49, 93, 0.03), 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.os-input-error {
  color: #ff3030;
  margin: 10px 0;
}
.os-input-container {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
}
.os-input-text {
  flex: 1 1;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  transition: border 0.2s ease;
  box-shadow: 0 0 0 1px rgba(220, 220, 220, 0.2);
}
.os-input-text:focus {
  border: 1px solid #0069ff;
  box-shadow: 0 0 0 0.5px rgba(49, 49, 93, 0.03), 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.os-input-error {
  color: #ff3030;
  margin: 10px 0;
}
.orion-select__control {
  min-height: 46px !important;
  margin-bottom: 10px !important;
}
.orion-select__placeholder {
  color: #aaa !important;
}
.orion-select__control--is-focused {
  border-color: #0069ff !important;
  box-shadow: 0 0 0 1px #0069ff !important;
}
.os_iconButton {
  color: #131313;
  cursor: pointer;
  position: relative;
}
.os_iconButton :hover {
  color: #2b2b2b;
}
.os_iconButton :active {
  color: #636363;
}
.os_iconButton_loading {
  position: relative;
  cursor: default;
  color: #989898;
}
.os_iconButton_disabled {
  position: relative;
  cursor: default;
  color: #989898;
}

